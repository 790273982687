import { gql } from '@apollo/client'
import uniqueId from 'lodash/uniqueId'

import { useEffect, useState } from 'react'
import { useToast } from 'src/components/toasts'
import {
  chunkArray,
  sequenceCallsWithProgress
} from 'src/utils/chunkAndSequenceWithProgress'

import {
  SUBMIT_ACTIVITY,
  SUBMIT_BULK_INCIDENT,
  SUBMIT_FORM
} from './mutations'
import { useApolloClientContext } from './useApolloClientProvider'

const getOfflineMutations = label => {
  if (label === 'bulkIncident') {
    return SUBMIT_BULK_INCIDENT
  }
  if (label === 'form') {
    return SUBMIT_FORM
  }
  return SUBMIT_ACTIVITY
}

export const GET_OFFLINE_QUEUE = gql`
  query GetQueue {
    queue @client
  }
`

export const useOfflineMutation = (dataType = 'incident') => {
  const [loading, setLoading] = useState(false)
  const client = useApolloClientContext()

  async function mutate(item, offlineOptions = {}) {
    setLoading(true)
    try {
      const query = await client.readQuery({
        query: GET_OFFLINE_QUEUE
      })
      const timePrefix = new Date().getTime()
      const qid = uniqueId(dataType + '_' + timePrefix + '_')

      const queueItem = {
        ...offlineOptions,
        data: item,
        qid,
        dataType: dataType
      }

      // item.dataType = dataType

      const res = await client.writeQuery({
        query: GET_OFFLINE_QUEUE,
        data: { queue: [...(query ? query.queue : []), queueItem] }
      })

      setLoading(false)
      return { ...res, offline: true }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  return {
    mutate,
    loading
  }
}

const useGetOfflineQueue = () => {
  const { add } = useToast()
  const [loading, setLoading] = useState(false)
  const client = useApolloClientContext()
  const [queue, setQueue] = useState(false)
  const [progress, setProgress] = useState(null) // State for progress

  useEffect(() => {
    async function loadQueue() {
      setLoading(true)
      const res = await client.readQuery({
        query: GET_OFFLINE_QUEUE
      })

      res && setQueue(res.queue)
      setLoading(false)
    }

    if (!queue) {
      loadQueue()
    }

    const querySubscription = client.watchQuery({
      query: GET_OFFLINE_QUEUE
    })

    querySubscription.subscribe({
      next: ({ data }) => {
        if (data) {
          setQueue(data.queue)
        }
      },
      error: e => console.error(e)
    })

    // return querySubscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function dequeueOperation(qid) {
    setLoading(true)

    const queue = await client.readQuery({
      query: GET_OFFLINE_QUEUE
    })
    const curQueue = queue ? queue.queue : []
    await client.writeQuery({
      query: GET_OFFLINE_QUEUE,
      data: {
        queue: curQueue.filter(item => item.qid !== qid)
      }
    })
    setLoading(false)
  }

  async function dequeueOperations(qids) {
    setLoading(true)

    const queue = await client.readQuery({
      query: GET_OFFLINE_QUEUE
    })
    const curQueue = queue ? queue.queue : []

    await client.writeQuery({
      query: GET_OFFLINE_QUEUE,
      data: {
        queue: curQueue.filter(
          item => !qids?.some(qid => qid === item.qid)
        )
      }
    })
    setLoading(false)
  }

  async function rerunOperation(op) {
    setLoading(op.qid)
    try {
      const res = await client.mutate({
        mutation: getOfflineMutations(op.dataType),
        variables: {
          ...op.data
        }
      })

      if (res.data) {
        add({
          content: 'Submission Successful!',
          color: 'success'
        })
        dequeueOperation(op.qid)
      } else {
        add({
          content:
            'Error submitting, please make sure your internet connection is working.',
          color: 'danger'
        })
      }
    } catch (e) {
      console.log(e)
      add({
        content:
          'Error submitting, please make sure your internet connection is working.',
        color: 'danger'
      })
    }

    setLoading(false)
  }

  // Function to rerun the entire queue
  async function rerunQueue() {
    setLoading(true)
    const chunkSize = 3
    const chunks = chunkArray(queue, chunkSize)
    const numChunks = chunks.length
    let errors = []
    let success = []

    try {
      setProgress(0)
      await sequenceCallsWithProgress(
        chunks,
        async (chunk, index) => {
          await Promise.all(
            chunk.map(async operation => {
              try {
                const res = await client.mutate({
                  mutation: getOfflineMutations(operation.dataType),
                  variables: {
                    ...operation.data
                  }
                })

                if (res.data) {
                  success.push(operation.qid)
                } else {
                  errors.push(operation.qid)
                }
              } catch (e) {
                errors.push(operation.qid)
                console.log(e)
              }
            })
          )

          const chunkProgress = Math.round(
            ((index + 1) / numChunks) * 100
          )
          setProgress(chunkProgress)
        }
      )

      if (success?.length > 0) {
        await dequeueOperations(success)
      }

      add({
        content:
          'Queue submit successful!' + ' ' + queue.length + ' items',
        color: 'success'
      })
      setProgress()
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return {
    progress,
    queue: queue,
    rerunOperation,
    rerunQueue,
    dequeueOperation,
    loading
  }
}

export default useGetOfflineQueue
