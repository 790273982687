import { useState } from 'react'

import HexColorPickerCustom from 'src/app/components/Shared/HexColorPicker'
import ModalButton from 'src/app/components/Shared/ModalButton'
import chroma from 'chroma-js'

import Button from 'src/app/components/Shared/Elements/Button'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'

const HexColorInputModal = ({
  color,
  defaultColor,
  onSave,
  text,
  handleSelect,
  loading
}) => {
  const [cleared, setCleared] = useState(false)

  const currentColor = color || defaultColor
  const buttonColor = defaultColor || color

  return (
    <ModalButton
      modalProps={{
        onClosed: () => {
          console.log('exit')
          setCleared(false)
          onSave &&
            handleSelect({ name: 'color', value: defaultColor })
        }
      }}
      modalHeader={text || 'Choose Color'}
      buttonProps={{
        variant: 'secondary',
        sx: {
          backgroundColor: buttonColor,
          border: buttonColor,
          color:
            buttonColor && chroma.contrast(buttonColor, 'white') < 4
              ? 'black'
              : 'white'
        }
      }}
      onClose={() => {
        setCleared(false)
      }}
      buttonLabel={
        text ? text : currentColor ? 'Change Color' : 'Choose Color'
      }
      renderModalActions={toggle => (
        <Button
          variant="primary"
          disabled={loading}
          icon={loading && 'spinner'}
          onClick={async () => {
            onSave && (await onSave())
            toggle()
            setCleared(false)
          }}
        >
          {onSave ? 'Save' : 'Choose Color'}
        </Button>
      )}
    >
      {({ toggle }) => (
        <>
          <FormGroup>
            <HexColorPickerCustom
              id="color"
              name="color"
              color={!cleared ? currentColor || undefined : undefined}
              onChange={c => {
                if (c && c.includes('NaNNaN')) return
                if (!c) {
                  setCleared(true)
                } else {
                  setCleared(false)
                }
                handleSelect({ name: 'color', value: c })
              }}
            />
          </FormGroup>
        </>
      )}
    </ModalButton>
  )
}

export default HexColorInputModal
