import React, { Fragment, useState, useMemo, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import path from 'path'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { v4 as uuid4 } from 'uuid'
import UppyPicker from '.'
import Compressor from '@uppy/compressor'
import Audio from '@uppy/audio'
import ImageEditor from '@uppy/image-editor'

import { Flex, Box } from 'theme-ui'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { useToast } from 'src/components/toasts'
import Spinner from 'src/images/icons/Spinner'

import moment from 'moment'
import Button from '../Elements/Button'
import Icon from '../Icon'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
// import '@uppy/webcam/dist/style.min.css'
import '@uppy/audio/dist/style.min.css'
import '@uppy/image-editor/dist/style.min.css'
import ViewFileModal from '../../Operations/Documents/ViewFileModal'
import * as exifr from 'exifr'

const GET_SIGNED_URL = gql`
  mutation GetSignedUrl($filename: String!, $contentType: String!) {
    getSignedUrl(filename: $filename, contentType: $contentType) {
      url
    }
  }
`

const AuthenticatedUppyFilePicker = props => {
  const {
    show,
    handleClose,
    onSuccess,
    pickerOptions,
    agencyId = 'public',
    limit
  } = props
  const [getSignedUrl] = useMutation(GET_SIGNED_URL)
  const [progress, setProgress] = useState(0)
  const fetchSignedUrl = async file => {
    try {
      const uuid = uuid4()
      const fileExtension = path.extname(file.name)
      const newFilename = `${agencyId}/${uuid}${fileExtension}`
      const res = await getSignedUrl({
        variables: {
          filename: newFilename,
          contentType: file.type
        }
      })
      return {
        originalFilename: file.name,
        filename: newFilename,
        contentType: file.type,
        signedUrl: res.data.getSignedUrl.url
      }
    } catch (error) {
      console.error('Error generating signed URL:', error)
    }
  }

  let uppyOptions = {
    id: 'uppy-instance',
    autoProceed: false,
    restrictions: { maxNumberOfFiles: limit }
  }

  if (pickerOptions && pickerOptions.accept) {
    uppyOptions.restrictions.allowedFileTypes = pickerOptions.accept
  }

  const uppy = useMemo(() => {
    return new Uppy(uppyOptions)
      .use(Compressor, {
        quality: 0.9
      })
      .use(ImageEditor, {
        id: 'ImageEditor'
      })
      .use(Audio, {})
      .use(AwsS3, {
        limit: limit,
        getUploadParameters: async file => {
          const fetchRes = await fetchSignedUrl(file)
          if (fetchRes.signedUrl) {
            file.meta.name = fetchRes.filename
            file.meta.type = fetchRes.contentType
            file.meta.orignalFilename = fetchRes.originalFilename
            return {
              method: 'PUT',
              url: fetchRes.signedUrl,
              headers: {
                'Content-Type': file.type
              },
              fields: {
                filename: fetchRes.filename,
                contentType: fetchRes.contentType
              }
            }
          } else {
            throw new Error('Failed to fetch signed URL')
          }
        }
      })
  }, [show])

  useEffect(() => {
    uppy.on('upload-progress', (file, progress) => {
      setProgress(progress)
    })

    uppy.on('file-added', async file => {
      if (!file.type?.includes('jpeg')) return

      const orientation = await exifr.orientation(file.data)

      if (orientation === 6) {
        try {
          const img = new Image()
          const objectUrl = URL.createObjectURL(file.data)

          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            // Set canvas dimensions
            canvas.width = img.height
            canvas.height = img.width

            // Rotate and draw the image
            ctx.translate(canvas.width / 2, canvas.height / 2)
            // ctx.rotate((90 * Math.PI) / 180)
            ctx.drawImage(img, -img.width / 2, -img.height / 2)

            // Convert canvas to Blob
            canvas.toBlob(async blob => {
              const newFile = new File([blob], file.name, {
                type: file.type
              })

              // Replace the old file with the new file
              uppy.setFileState(file.id, {
                ...file,
                data: newFile
              })
            }, file.type)
          }

          img.src = objectUrl
        } catch (error) {
          console.error('Error processing image:', error)
        }
      }
    })
    uppy.on('complete', result => {
      const uploadedFiles = []
      for (const file of result.successful) {
        uploadedFiles.push({
          filename: file.meta.orignalFilename,
          mimetype: file.meta.type,
          handle: file.meta.name,
          id: file.id
        })
      }
      onSuccess(uploadedFiles)
      handleClose()
    })

    return () => uppy.close()
  }, [uppy])

  function closeModal() {
    handleClose()
  }

  if (!show) {
    return <Fragment />
  }

  return (
    <>
      <UppyPicker
        uppy={uppy}
        open={show}
        handleClose={closeModal}
        progress={progress}
      />
    </>
  )
}

export const UppyFileUploadButton = ({
  files = [],
  onAttach,
  containerProps,
  onDetach,
  isUpdate,
  disabled,
  multipleFiles,
  startOpen = false
}) => {
  const { currentAgency, user } = useGetCurrentAgencyContext()
  const [fileSuccess, setFileSuccess] = useState(false)
  const [show, setShow] = useState(startOpen)
  const [showPreview, setShowPreview] = useState(false)
  const { add } = useToast()
  const hasFiles = files && files.length > 0

  return (
    <>
      <AuthenticatedUppyFilePicker
        limit={multipleFiles ? 0 : 1}
        agencyId={currentAgency.id}
        show={show}
        handleClose={() => setShow(false)}
        onFinish={() => {
          setShow(false)
        }}
        onSuccess={res => {
          if (res && res.length > 0) {
            const uploadedFiles = res.map(file => {
              return {
                name: file.filename,
                fileType: file.mimetype,
                filestackId: file.handle,
                uploadedAt: new Date(),
                userId: user.id,
                agencyId: currentAgency.id
              }
            })
            onAttach(uploadedFiles)
            setFileSuccess(true)
          } else {
            add({
              content: 'Error uploading file.',
              color: 'danger'
            })
          }
        }}
      />

      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
        {...containerProps}
      >
        <Button
          disabled={disabled}
          onClick={() => {
            setShow(true)
          }}
          variant="naked"
          sx={{
            height: '100%',
            padding: '24px',
            color: hasFiles ? 'success' : null,
            display: 'flex',
            flexDirection: 'column',
            border: hasFiles
              ? '1px dashed gray'
              : '1px dashed lightgray'
          }}
          type="button"
        >
          <Box
            sx={{
              display: 'block',
              margin: 'auto'
            }}
          >
            {showPreview || show ? (
              <Spinner />
            ) : (
              <Icon
                icon={hasFiles ? 'UploadSuccess' : 'Upload'}
                height={'24px'}
                width={'24px'}
                color={hasFiles && 'success'}
              />
            )}
          </Box>
          {hasFiles
            ? isUpdate && fileSuccess
              ? 'File Updated. Please save..'
              : isUpdate
              ? `${files.length} File${files.length > 0 &&
                  's'} Attached (Click to add)`
              : `${files.length} File${files.length > 0 &&
                  's'} Attached`
            : multipleFiles
            ? 'Upload Files'
            : 'Upload File'}
        </Button>
        {showPreview && (
          <ViewFileModal
            closeModal={() => setShowPreview(false)}
            f={showPreview}
          />
        )}
        <Box>
          {hasFiles &&
            files.map((file, i) => (
              <Flex
                key={i}
                sx={{
                  p: '3px',
                  bg: 'gray300',
                  borderRadius: '4px',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                  alignItems: 'center',
                  fontWeight: 'bold'
                }}
              >
                {file.fileType} at{' '}
                {moment(file.createdAt || file.uploadedAt).format(
                  'HH:mm:ss'
                )}
                <Flex>
                  {isUpdate && !fileSuccess && (
                    <Button
                      sx={{ marginLeft: '3px' }}
                      size="sm"
                      onClick={() => setShowPreview(file)}
                    >
                      View
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      onDetach(file)
                    }}
                    size="sm"
                    variant="linkDanger"
                    icon="trash"
                  />
                </Flex>
              </Flex>
            ))}
        </Box>
      </Flex>
    </>
  )
}

export default AuthenticatedUppyFilePicker
