import { lazy, Suspense, useEffect } from 'react'

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  useLocation
} from 'react-router-dom'

import Layout from 'src/components/layout'

import PrivateRoute from './components/PrivateRoute'
import Homebase from './containers/HomeBase'

import SEO from '../components/seo'
import NotFound from '../pages/404'
import { CenteredContent } from './components'
import Login from './containers/LoginScreen'
import SetupAccount from './containers/SetupAccount'
import UpdatePassword from './containers/UpdatePassword'

import { WrapRootReact } from './services/wrap-wroot-element'

import Spinner from 'src/images/icons/Spinner'

import { retry } from 'src/utils'
import AuthError from './containers/AuthError'
import Oauth from './containers/OauthScreen'
import Onboarding from './containers/Onboarding'
import SubmissionPageTabs from './components/QuickSurvey/SubmissionPageTabs'
import { STATUS_NODE_TYPES } from './components/Statuses/utils'

const Loading = props => {
  if (props.error) {
    return (
      <CenteredContent>
        There may have been an error loading the page. Please refresh.
      </CenteredContent>
    )
  } else {
    return <Spinner centered />
  }
}

const AsyncActivityLog = lazy(() =>
  retry(() => import('./containers/ActivityLog'))
)
const AsyncDocuments = lazy(() =>
  retry(() => import('./containers/Operations/Documents'))
)

const AsyncForms = lazy(() =>
  retry(() => import('./containers/Operations/Forms'))
)

const AsyncTags = lazy(() =>
  retry(() => import('./containers/Operations/Tags'))
)

const AsyncReportingIncidents = lazy(() =>
  retry(() => import('./containers/Reporting/Incidents'))
)

const AsyncReportingForms = lazy(() =>
  retry(() => import('./containers/Reporting/Forms'))
)
const AsyncReportingReports = lazy(() =>
  retry(() => import('./containers/Reporting/Reports'))
)
const AsyncPulseReports = lazy(() =>
  retry(() => import('./containers/Statuses/Reports'))
)

const AsyncDispatching = lazy(() =>
  retry(() => import('./containers/Dispatches'))
)

const AsyncBulletinsPage = lazy(() =>
  retry(() => import('./containers/Bulletins'))
)

const AsyncPublicBroadcasts = lazy(() =>
  retry(() => import('./containers/Public/Broadcasts'))
)

const AsyncPublicDashboard = lazy(() =>
  retry(() => import('./containers/Public/DashboardManager'))
)

const AsyncStatusDashboard = lazy(() =>
  retry(() => import('./containers/Statuses/Pulse'))
)

const AsyncProfile = lazy(() =>
  retry(() => import('./containers/AgencyProfile'))
)

const AsyncRoster = lazy(() =>
  retry(() => import('./containers/Agency/Roster'))
)

const AsyncResources = lazy(() =>
  retry(() => import('./containers/Agency/Resources'))
)

const AsyncAssets = lazy(() =>
  retry(() => import('./containers/Agency/Assets'))
)

const AsyncLocations = lazy(() =>
  retry(() => import('./containers/Agency/Locations'))
)

const AsyncStats = lazy(() =>
  retry(() => import('./containers/Agency/Stats'))
)

const AsyncWorkflows = lazy(() =>
  retry(() => import('./containers/Agency/Workflows'))
)

const AsyncGroups = lazy(() =>
  retry(() => import('./containers/Agency/Groups'))
)

const OpertaionsNavigate = () => {
  const location = useLocation()
  if (location.search?.includes('form')) {
    return (
      <Navigate to={`/agency/operations/forms${location?.search}`} />
    )
  }

  return <Navigate to="/agency/operations/documents" />
}

const Wrap = () => {
  const location = useLocation()
  useEffect(() => {
    trackPageView()
  }, [location])

  return (
    <WrapRootReact>
      <Layout>
        <SEO
          title="Watchtower - Digital Command Center"
          description="A digital command center for public agencies."
        />
        <Outlet />
      </Layout>
    </WrapRootReact>
  )
}

function trackPageView() {
  if (window.posthog) {
    window.posthog.capture('$pageview')
  }
}

const QuickTapSurveyRoute = props => {
  return <SubmissionPageTabs {...props} />
}

export const RouteTree = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Wrap />}>
      {/* <Redirect noThrow={true} from="/" to="/agency" /> */}
      <Route
        path="/agency"
        element={<PrivateRoute component={Homebase} />}
      />

      <Route
        path="/agency/reporting/reports"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncReportingReports />
              </Suspense>
            )}
          />
        }
      />

      <Route
        path="/agency/reporting/incidents"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncReportingIncidents />
              </Suspense>
            )}
          />
        }
      />

      <Route
        path="/agency/reporting/forms"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncReportingForms />
              </Suspense>
            )}
          />
        }
      />

      <Route
        path="/agency/submission"
        element={<PrivateRoute component={QuickTapSurveyRoute} />}
      />
      <Route
        path="/agency/dispatches"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncDispatching />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/stats"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncActivityLog />
              </Suspense>
            )}
          />
        }
      />

      <Route
        path="/agency/communications"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncBulletinsPage />
              </Suspense>
            )}
          />
        }
      />

      <Route
        path="/agency/public/broadcasts"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncPublicBroadcasts />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/public/dashboard"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncPublicDashboard />
              </Suspense>
            )}
          />
        }
      />

      {/* Agency profile and sub-nav items */}
      <Route
        path="/agency/profile"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncProfile />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/profile/roster"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncRoster />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/pulse/reports"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncPulseReports />
              </Suspense>
            )}
          />
        }
      />
      {STATUS_NODE_TYPES?.map(nodeType => (
        <Route
          path={`/agency/pulse/${nodeType?.toLowerCase()}`}
          element={
            <PrivateRoute
              component={() => (
                <Suspense fallback={<Loading />}>
                  <AsyncStatusDashboard nodeType={nodeType} />
                </Suspense>
              )}
            />
          }
        />
      ))}

      <Route
        path="/agency/profile/resources"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncResources />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/profile/locations"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncLocations />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/profile/assets"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncAssets />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/profile/stat-categories"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncStats />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/profile/groups"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncGroups />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/profile/workflows"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncWorkflows />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/operations/documents"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncDocuments />
              </Suspense>
            )}
          />
        }
      />

      <Route
        path="/agency/operations/tags/"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncTags />
              </Suspense>
            )}
          />
        }
      />

      <Route
        path="/agency/operations/forms"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncForms />
              </Suspense>
            )}
          />
        }
      />
      <Route
        path="/agency/operations/forms/:formId"
        element={
          <PrivateRoute
            component={() => (
              <Suspense fallback={<Loading />}>
                <AsyncForms />
              </Suspense>
            )}
          />
        }
      />

      <Route path="/agency/auth-error" element={<AuthError />} />

      <Route path="/agency/login" element={<Login />} />
      <Route path="/agency/oauth" element={<Oauth />} />
      <Route
        path="/agency/update-password"
        element={<UpdatePassword />}
      />
      <Route path="/agency/set-up" element={<SetupAccount />} />
      <Route path="/agency/onboarding" element={<Onboarding />} />

      <Route
        path="/agency/operations"
        element={<OpertaionsNavigate />}
      />
      <Route path="/" element={<Navigate to="/agency" replace />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
)
