/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react'
import Spinner from 'src/images/icons/Spinner'

import { Flex, Box } from 'theme-ui'

import { useMutation, useQuery } from '@apollo/client'

import useForm from 'src/app/hooks/useForms'

import { UPDATE_FORM_RESPONSE } from 'src/app/hooks/mutations'
import { useToast } from 'src/components/toasts'
import FormError, {
  cleanGraphQLError
} from '../../../Forms/FormError'

import { prettyDate } from 'src/utils'

import { Divider } from '../../../Shared'

import FormFieldRenderer from './FormFieldRenderer'
import {
  mapValuesToSubmitForm,
  mapValuesToUpdateFormResponse
} from './dataMapping'
import flatMap from 'lodash/flatMap'
import {
  GET_FORM,
  useGetCurrentAgencyContext
} from 'src/app/hooks/queries'
import Button from 'src/app/components/Shared/Elements/Button'
import { FormContext, getValueOfOtherAnswer } from './utils'
import isEmpty from 'lodash/isEmpty'

import InlineTooltip from 'src/app/components/Shared/InlineTooltip'
import Badge from 'src/app/components/Shared/Elements/Badge'
import QuestionTooltip from 'src/app/components/Shared/QuestionTooltip'
import { CenteredContent } from 'src/app/components'
import { useNetworkStatus } from 'src/app/hooks/useNetworkStatus'
import { validateEmail, validatePhone } from 'src/utils/validations'

import { EditWeatherLogModalForm } from 'src/app/components/Forms/EditWeatherLogModalForm'
import FormNavigation from 'src/app/components/Shared/Forms/FormScrollNavigation'
import stripHtml from 'src/utils/stripHtml'
import { useSubmitForm } from 'src/app/hooks/useSubmitForm'
import { FormPageTypes } from 'src/app/containers/Operations/utils'
import { useScrollToFormSection } from 'src/app/hooks/useScrollToFormSection'
import moment from 'moment'
import filterNodesByType from 'src/utils/filterNodesByType'
import useGetTodaysAssignment from 'src/app/hooks/useGetTodaysAssignment'

function mergeUpdatedFieldItems(values, recentSavedResponse) {
  if (!recentSavedResponse) {
    return values
  }

  const flattFieldItems = flatMap(
    recentSavedResponse.fields,
    'fieldItems'
  )

  // NEED to move to more atomic form updates, like field item by field item... to avoid this
  // This function helps avoid rerenders during autoSave... we are going to check if the fieldItems have been updated
  // and mapping their ids in the db to their fieldItems in the form state before resubmitting.  This is important when a form is submit and editted multiple times before a reload
  // If this is not done, certain fields like file and address, will result in duplications as they are a nested create upsert
  // This is only required for related nodes that require creation: file, location, etc
  // Not for connected nodes like user, asset, etc
  return {
    ...values,
    updateFieldItems: values.updateFieldItems.map(
      updatedFieldItem => {
        const recent = flattFieldItems?.find(
          fi =>
            fi.formFieldItem?.id === updatedFieldItem.id ||
            fi.id === updatedFieldItem.id
        )
        const value = recent?.[recent.type]

        return {
          ...(value &&
          (recent.type === 'locations' || recent.type === 'file')
            ? {
                [recent.type]: updatedFieldItem[recent.type]
                  ?.filter(
                    // Remove any lingering removed files that were never saved..
                    // these can just be disregarded and throws off id merging
                    fi => !fi.removed || (fi.removed && !fi.id)
                  )
                  ?.map((item, k) => {
                    return {
                      ...item,
                      id: !item.id ? value[k]?.id : item.id
                    }
                  })
              }
            : updatedFieldItem),
          fieldId: recent?.formField?.id || updatedFieldItem.fieldId,
          id: recent?.id || updatedFieldItem.id
        }
      }
    )
  }
}

function getDefaultAnswer(
  fieldItem,
  assignmentInfo,
  users,
  positions
) {
  let allowedValues = fieldItem.allowedValues
  let valueTypeFormat = fieldItem.valueTypeFormat
  let defaults =
    fieldItem.type === 'users'
      ? fieldItem.allowMultipleAnswers
        ? assignmentInfo?.map(a => ({
            name: a.responderName,
            id: a.responderId
          })) || []
        : [
            {
              name: assignmentInfo?.[0]?.responderName,
              id: assignmentInfo?.[0]?.responderId
            }
          ]
      : fieldItem.type === 'positions'
      ? fieldItem.allowMultipleAnswers
        ? assignmentInfo?.map(a => ({
            name: a.positionName,
            id: a.positionId
          })) || []
        : [
            {
              name: assignmentInfo?.[0]?.positionName,
              id: assignmentInfo?.[0]?.positionId
            }
          ]
      : []

  if (allowedValues?.length > 0) {
    // Update allowedValues using getValidIdsByIdORTag
    allowedValues = getValidIdsByIdORTag(
      fieldItem.type === 'positions'
        ? filterNodesByType(positions, valueTypeFormat)
        : users,
      allowedValues
    )

    // Convert allowedValues to a Set for efficient lookup
    const allowedIdsSet = new Set(allowedValues)

    // Filter defaults to include only those with ids in allowedValues
    defaults = defaults.filter(d => allowedIdsSet.has(d.id))
  }

  return defaults
}

function mergeSavedResponseWithCurrentValues({
  form,
  response,
  values,
  stepId,
  submissionId,
  assignmentInfo,
  users,
  positions
}) {
  let withResponse = {
    fields: form?.fields
      ?.filter(field => !field.archived)
      .map(field => ({
        ...field,
        fieldItems: field.fieldItems
          .filter(fieldItem => !fieldItem.archived)
          .map(fieldItem => {
            const isUseAssignment = fieldItem.useAssignment

            if (isUseAssignment) {
              return {
                ...fieldItem,
                value: getDefaultAnswer(
                  fieldItem,
                  assignmentInfo,
                  users,
                  positions
                )
              }
            }

            return fieldItem
          })
      }))
  }

  if (response) {
    withResponse = {
      ...response,
      fields: form.fields.map(field => {
        const fieldResponse =
          response.fields.find(f => {
            const formFieldId = f?.formField?.id || f.id

            return formFieldId === field.id
          }) || {}

        const vFieldResponse = values?.fields?.find(f => {
          const formFieldId = f?.formField?.id || f.id

          return formFieldId === field.id
        })

        return {
          ...field,
          ...fieldResponse,
          id: fieldResponse?.id || `new_${field.id}`,
          fieldItems: field.fieldItems.map(fieldItem => {
            const fieldItemResponse =
              fieldResponse?.fieldItems?.find(
                f => (f?.formFieldItem?.id || f.id) === fieldItem.id
              ) || {}

            let valuesWithOther = {}

            const vFieldItemResponse = vFieldResponse?.fieldItems?.find(
              f => (f?.formFieldItem?.id || f.id) === fieldItem.id
            )

            const currentValue =
              vFieldItemResponse || fieldItemResponse
            const otherValue =
              fieldItem.allowedValues &&
              fieldItem.allowedValues.length &&
              getValueOfOtherAnswer(
                fieldItem.allowedValues,
                currentValue?.value,
                fieldItem.type
              )

            if (otherValue) {
              valuesWithOther = {
                value: fieldItem?.allowMultipleAnswers
                  ? [
                      ...(Array.isArray(currentValue?.value)
                        ? currentValue?.value.map(fi => {
                            return !fi?.other && fi === otherValue
                              ? { other: otherValue }
                              : fi
                          })
                        : [
                            {
                              other: otherValue
                            }
                          ])
                    ]
                  : otherValue
              }
            }

            const curVal =
              currentValue?.[fieldItem?.type] || currentValue?.value

            return {
              ...fieldItem,
              ...currentValue,
              id: fieldItemResponse?.id || `new_${fieldItem.id}`,
              value: fieldItem?.allowMultipleAnswers
                ? curVal
                  ? [...(Array.isArray(curVal) ? curVal : [curVal])]
                  : []
                : currentValue?.[fieldItem?.type] ||
                  currentValue?.value,
              // ...fieldItemResponse,
              ...valuesWithOther
            }
          })
        }
      })
    }
  }

  return {
    draft: true,
    formId: form.id,
    formName: form.name,
    submissionId,
    stepId,
    updateFieldItems: [],
    ...withResponse
  }
}

const FormSubmitRenderer = ({
  formResponse,
  formId,
  onFinish,
  hideTitle,
  onFormSubmit,
  onDraftFinish,
  disableAutoSave,
  submitToastText,
  submissionId,
  style = {},
  stepId,
  refetchQueries
}) => {
  const {
    setRef,
    scrollToSection,
    activeSectionId
  } = useScrollToFormSection()
  const online = useNetworkStatus()
  const { add } = useToast()
  const [lastSavedResponse, setLastSaved] = useState(null)
  const [response, setResponse] = useState(formResponse)
  const {
    user,
    currentAgency: agency,
    users,
    positions
  } = useGetCurrentAgencyContext()
  const { data: todaysAssignment } = useGetTodaysAssignment()

  const [inputIsFocused, setInputIsFocused] = useState(false)

  const { data, loading: loadingForm } = useQuery(GET_FORM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      where: { id: formId },
      fieldsWhere: {
        archived: {
          equals: false
        }
      },
      fieldItemsWhere: {
        archived: {
          equals: false
        }
      }
    }
  })
  const form = data && data.form
  const isMedicalForm = form?.medicalFormConfig
  const isSupervisorReview = form?.supervisorReviewConfig

  const isAnAutoSaveForm =
    online &&
    form?.autoSave &&
    !loadingForm &&
    !disableAutoSave &&
    (!formResponse || formResponse.draft)

  useEffect(() => {
    const handleFocus = event => {
      const target = event?.target
      const skipReactSelect = target?.id?.includes('react-select')

      if (
        (target?.tagName === 'INPUT' ||
          target?.tagName === 'TEXTAREA') &&
        !skipReactSelect
      ) {
        setInputIsFocused(true)
      }
    }

    const handleBlur = event => {
      const target = event.target
      const skipReactSelect = target?.id?.includes('react-select')
      if (
        (target?.tagName === 'INPUT' ||
          target?.tagName === 'TEXTAREA') &&
        !skipReactSelect
      ) {
        setInputIsFocused(false)
      }
    }

    if (isAnAutoSaveForm) {
      window.addEventListener('focus', handleFocus, true)
      window.addEventListener('blur', handleBlur, true)
    }

    return () => {
      window.removeEventListener('focus', handleFocus, true)
      window.removeEventListener('blur', handleBlur, true)
    }
  }, [isAnAutoSaveForm])

  const { submitForm, loading: submitting } = useSubmitForm(
    {
      refetchQueries: refetchQueries
    },
    {
      formName: data?.form?.name,
      incidentDate: new Date()
    }
  )

  const [updateOneFormResponse, { loading: updating }] = useMutation(
    UPDATE_FORM_RESPONSE,
    {
      onCompleted: data => {
        setLastSaved(data?.updateOneFormResponse)
      }
    }
  )

  const mutating = submitting || updating

  const {
    values,
    setValues,
    setErrors,
    errors,
    handleErrors,
    lastSave,
    mergeValues,
    autoSave,
    handleSubmit,
    ...formProps
  } = useForm(
    onSubmit,
    {
      draft: true
    },
    {
      autoSave: isAnAutoSaveForm
    }
  )

  function onSubmit(vals) {
    const mutate = values.id
      ? () => updateFormResponse(vals)
      : () => createFormResponse(vals)

    if (!submitting) {
      mutate()
    }
  }

  useEffect(() => {
    if (form) {
      // Set up state for the form.  if there is a response, merge it with the form values.
      // Prioritize current values to avoid state issues

      const formResponseWithOtherValues = mergeSavedResponseWithCurrentValues(
        {
          form,
          response,
          values,
          submissionId,
          stepId,
          assignmentInfo: todaysAssignment?.todaysResponders,
          users,
          positions
        }
      )
      if (isAnAutoSaveForm && !response) {
        handleSubmit(null, formResponseWithOtherValues)
      }

      setValues(formResponseWithOtherValues, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, response, isAnAutoSaveForm])

  function fieldItemValidation(fieldItems) {
    if (!fieldItems) return []

    return fieldItems
      .filter(f => {
        if (f.type === 'dateTime' && f.value) {
          return (
            !moment(f.value).isAfter('0000-01-01T00:00:00.000Z') ||
            !moment(f.value).isBefore(
              moment().format('3000-01-01T00:00:00.000Z')
            )
          )
        }

        return (
          (f.valueTypeFormat === 'email' &&
            f.value &&
            !validateEmail(f.value)) ||
          (f.valueTypeFormat === 'phone' &&
            f.value &&
            !validatePhone(f.value))
        )
      })
      .map(f => ({
        id: f.id,
        message: `Please enter a valid ${f.valueTypeFormat || f.type}`
      }))
  }

  function fieldItemRequiredFields(fieldItems) {
    return fieldItems
      .filter(f => {
        const isRequired = !f.archived && f.required

        if (!isRequired) return false

        if (Array.isArray(f.value)) {
          return f.value?.length < 1
        }
        return f.value === undefined || f.value === null
      })
      .map(f => ({
        id: f.id,
        message: `This field is required`
      }))
  }

  async function createFormResponse(values) {
    const fieldItems = flatMap(
      values.fields,
      field => field.fieldItems
    )
    const requiredFieldItemIds = fieldItemRequiredFields(fieldItems)

    const formErrorFieldItemIds = fieldItemValidation(fieldItems)

    const errors = [...requiredFieldItemIds, ...formErrorFieldItemIds]

    if (!values.draft && errors.length > 0) {
      setErrors(
        errors.reduce((acc, fieldItem) => {
          return {
            ...acc,
            [fieldItem.id]:
              fieldItem.message || `This field is required`
          }
        }, {})
      )
      // If there are errors, set the form to draft until submit again..
      setValues({
        ...values,
        draft: true
      })
      return
    }

    if (values.draft && formErrorFieldItemIds.length > 0) {
      setErrors(
        formErrorFieldItemIds.reduce((acc, fieldItem) => {
          return {
            ...acc,
            [fieldItem.id]: fieldItem.message
          }
        }, {})
      )
      // If there are errors, set the form to draft until submit again..
      setValues({
        ...values,
        draft: true
      })
      return
    }

    try {
      let res
      if (onFormSubmit) {
        onFormSubmit(values)
        res = {}
      } else {
        const data = mapValuesToSubmitForm(values)

        res = await submitForm({
          variables: { data }
        })
      }

      if (res && !res.errors) {
        if (values.draft && res?.data?.submitForm) {
          add({
            color: 'success',
            content:
              "Form saved as draft.. click 'Submit' to finalize."
          })
          onDraftFinish && onDraftFinish()
          setResponse(res?.data?.submitForm?.formResponse)
        } else {
          add({
            color: 'success',
            content: submitToastText || 'Form submitted'
          })
          onFinish && onFinish()
        }
      } else {
        add({
          content: cleanGraphQLError(res?.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      console.log(e)
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  async function updateFormResponse(values) {
    try {
      const fieldItems = flatMap(
        values.fields,
        field => field.fieldItems
      )
      const requiredFieldItemIds = fieldItemRequiredFields(fieldItems)

      const formErrorFieldItemIds = fieldItemValidation(fieldItems)

      const errors = [
        ...requiredFieldItemIds,
        ...formErrorFieldItemIds
      ]

      if (!values.draft && errors.length > 0) {
        setErrors(
          errors.reduce((acc, fieldItem) => {
            return {
              ...acc,
              [fieldItem.id]:
                fieldItem.message || `This field is required`
            }
          }, {})
        )
        // If there are errors, set the form to draft until submit again..
        mergeValues({
          draft: true
        })
        return
      }

      if (values.draft && formErrorFieldItemIds.length > 0) {
        setErrors(
          formErrorFieldItemIds.reduce((acc, fieldItem) => {
            return {
              ...acc,
              [fieldItem.id]: fieldItem.message
            }
          }, {})
        )
        // If there are errors, set the form to draft until submit again..
        setValues({
          ...values,
          draft: true
        })
        return
      }

      let res = {}

      if (onFormSubmit) {
        res = onFormSubmit(values)
      } else {
        const variables = mapValuesToUpdateFormResponse(
          mergeUpdatedFieldItems(values, lastSavedResponse),
          agency?.id,
          user?.id,
          values.draft
        )

        res = await updateOneFormResponse({
          variables
        })
      }

      if (res && !res.errors) {
        // Clear updated fields..
        mergeValues({
          updateFieldItems: []
        })

        if (!res?.data?.updateOneFormResponse?.draft) {
          add({
            color: 'success',
            content: submitToastText || 'Form updated'
          })
          onFinish && onFinish()
        }
      } else {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  if (!values.fields) {
    return <Spinner centered />
  }

  const formTypeInfo = getFormTypeStylingInfo({
    isMedicalForm,
    isSupervisorReview
  })

  const disabled = loadingForm || mutating
  const submitDisabled =
    disabled || (isAnAutoSaveForm && inputIsFocused)
  const canBeDrafted =
    !form?.supervisorReviewConfig && (response?.draft || !response)

  return (
    <Box as="form" onSubmit={handleSubmit} sx={style}>
      <FormContext.Provider
        value={{
          values,
          setValues,
          setErrors,
          isSupervisorReview,
          errors,
          handleErrors,
          lastSave,
          autoSave,
          handleSubmit,
          loading: updating || submitting,
          ...formProps
        }}
      >
        <Flex
          sx={{
            p: 10,
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
            mt: '10px',
            ...(formTypeInfo?.accent && {
              bg: formTypeInfo.accent
            })
          }}
        >
          {!hideTitle && (
            <FormTitle
              form={form}
              response={response}
              formTypeInfo={formTypeInfo}
            />
          )}

          <Flex sx={{ gap: '10px', alignItems: 'center' }}>
            {form.autoWeather && (
              <Badge variant="primary">Auto Weather: ON</Badge>
            )}
            {loadingForm ? (
              <>
                <Spinner /> Checking for updates..
              </>
            ) : (
              canBeDrafted && (
                <Box>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    <Button
                      variant={'primary'}
                      onClick={() => {
                        setErrors({})
                        setValues({
                          ...values,
                          draft: true
                        })
                      }}
                      outline
                      block
                      size="sm"
                      disabled={disabled}
                      type="submit"
                      icon={
                        updating || submitting ? 'spinner' : undefined
                      }
                    >
                      Save Progress
                    </Button>
                    <QuestionTooltip
                      tip={
                        'Saving progress allows you to save your form inputs without running any validation. This is useful if you need to save your progress and come back to it later. It will mark the response as a draft and bypass any pulse automations and alerts until your submit.'
                      }
                      toolTipProps={{
                        placement: 'bottom',
                        sx: { minWidth: '200px' }
                      }}
                    />
                  </Flex>
                  {lastSave && (
                    <Box
                      sx={{
                        color: 'gray600',
                        fontSize: '12px',
                        mt: '5px'
                      }}
                    >
                      {lastSave}
                    </Box>
                  )}
                </Box>
              )
            )}
          </Flex>
        </Flex>
        {values.fields?.length > 1 && (
          <FormNavigation
            scrollToSection={scrollToSection}
            items={values.fields?.map(f => {
              let errorCount = 0
              f.fieldItems?.forEach(fi => {
                errorCount =
                  errors[fi.id]?.length > 0
                    ? errorCount + 1
                    : errorCount
              })
              return {
                id: f.id,
                label: stripHtml(f.title),
                color: errorCount > 0 ? 'danger' : '',
                badge: errorCount ? errorCount : null
              }
            })}
            activeSectionId={activeSectionId}
          />
        )}
        <>
          {isCuid(response?.id) && !response?.draft && (
            <InlineTooltip
              header="Editing a past form response."
              variant="warning"
              my="10px"
              tip=" This will NOT trigger any status updates.  Either manually update the status or submit a new form response."
            />
          )}
          {!formTypeInfo?.accent && <Divider margin="0px" />}

          <Flex sx={{ flexDirection: 'column' }}>
            {!response &&
            form.autoSave &&
            (submitting || loadingForm) ? (
              <CenteredContent>
                Setting up your form draft...
              </CenteredContent>
            ) : (
              values.fields.map(field => {
                return (
                  <FormFieldRenderer
                    ref={setRef(field.id)}
                    sectionName={field.id}
                    isUpdate={response}
                    key={field.id}
                    {...field}
                  />
                )
              })
            )}
            {form.autoWeather && (
              <Box sx={{ p: 20 }}>
                <EditWeatherLogModalForm
                  values={values.weather}
                  showPreviewWeather={response?.draft || !response}
                  handleChange={e => {
                    setValues({
                      ...values,
                      weather: {
                        ...values.weather,
                        [e.target?.name]: e.target?.value
                      }
                    })
                  }}
                />
              </Box>
            )}
          </Flex>

          {!isEmpty(errors) && (
            <FormError
              error={'Please fix all errors and re-submit'}
            />
          )}
          <>
            <Box
              sx={{
                position: 'sticky',
                bottom: '0px',
                paddingY: '10px',
                mb: '5px',
                bg: 'background',

                right: 0,
                left: 0
              }}
            >
              <Button
                variant={
                  submitDisabled
                    ? 'secondary'
                    : formTypeInfo?.color || 'success'
                }
                block
                size="lg"
                disabled={submitDisabled}
                type="submit"
                iconColor="background"
                icon={updating || submitting ? 'spinner' : undefined}
                onClick={() => {
                  setValues({
                    ...values,
                    draft: false
                  })
                }}
              >
                {!response
                  ? formTypeInfo.buttonText || 'Submit'
                  : response.draft
                  ? 'Submit'
                  : 'Update'}
              </Button>
            </Box>
            {canBeDrafted && (
              <Button
                variant={'secondary'}
                onClick={() => {
                  setErrors({})
                  setValues({
                    ...values,
                    draft: true
                  })
                }}
                outline
                block
                size="lg"
                disabled={disabled}
                type="submit"
                icon={updating || submitting ? 'spinner' : undefined}
              >
                Save Progress
              </Button>
            )}
          </>
        </>
      </FormContext.Provider>
    </Box>
  )
}

function getFormTypeStylingInfo({
  isMedicalForm,
  isSupervisorReview
}) {
  if (isMedicalForm) {
    return FormPageTypes.medical
  }
  if (isSupervisorReview) {
    return FormPageTypes.supervisor
  }
  return FormPageTypes.general
}

function FormTitle({ form, response, formTypeInfo }) {
  return (
    <Box sx={{ flex: 1, minWidth: '250px' }}>
      <Flex
        sx={{
          alignItems: 'center',
          gap: '5px',

          flexWrap: 'wrap-reverse'
        }}
      >
        {form?.formTemplate && form?.formTemplate.secureImageUrl && (
          <img
            alt={form?.name}
            src={form.formTemplate.secureImageUrl}
            width="60px"
            height="60px"
          />
        )}
        <h4 style={{ margin: 0, marginRight: '5px' }}>
          {form?.name}{' '}
        </h4>
        {formTypeInfo.title && formTypeInfo.color && (
          <Badge
            variant={formTypeInfo?.color}
            icon={formTypeInfo?.icon}
          >
            {formTypeInfo.title}
          </Badge>
        )}

        {response?.draft && <Badge variant="warning">Draft</Badge>}
      </Flex>
      {response?.submittedBy && (
        <p
          style={{
            marginLeft: 5,
            margin: 0,
            marginTop: '4px'
          }}
        >
          {`${response.draft ? 'Started' : 'Submitted'} by ${
            response.submittedBy.name
          } on ${prettyDate(response.createdAt)}`}
        </p>
      )}
    </Box>
  )
}

function isCuid(id) {
  return id && id.length > 20
}

export default FormSubmitRenderer

function getValidIdsByIdORTag(items, ids) {
  // Convert the ids array to a Set for efficient lookup
  const idSet = new Set(ids)
  const result = []

  items.forEach(item => {
    // Check if the item's id matches any id in the ids array
    if (idSet.has(item.id)) {
      result.push(item.id)
    } else {
      // Combine tags and displayTags into a single array
      const allTags = (item.tags || []).concat(
        item.category ? [item.category] : []
      )
      // Check if any tag's id matches
      const hasMatchingTag = allTags.some(tag => idSet.has(tag.id))
      if (hasMatchingTag) {
        result.push(item.id)
      }
    }
  })

  return result
}
