/** @jsxImportSource theme-ui */

import { gql, useQuery } from '@apollo/client'
import ModalButton from './Shared/ModalButton'
import { prettyDate } from 'src/utils'
import ListGroup from './Shared/Elements/ListGroup'
import ListGroupItemHeading from './Shared/Elements/ListGroupItemHeading'
import CollapsablePanel from './Shared/CollapsablePanel'
import Badge from './Shared/Elements/Badge'
import { useGetCurrentAgencyContext } from '../hooks/queries'
import Button from './Shared/Elements/Button'
import stripHtml from 'src/utils/stripHtml'
import last from 'lodash/last'
import uniqBy from 'lodash/uniqBy'

const AUDIT_LOG = gql`
  query UserAuditLogs($rowId: ID!) {
    userAuditLogs(rowId: $rowId) {
      id
      action
      timestamp
      actionBy
      table
      details {
        fieldName
        prettyFieldName
        oldValue
        newValue
        contextIds
      }
    }
  }
`

const UserActionAuditModalContent = ({
  rowId,
  contextData,
  includeFieldNameWithContext,
  propNodes
}) => {
  const {
    users,
    positions,
    assets,
    positionCategories,
    categories
  } = useGetCurrentAgencyContext()
  const { loading, data, fetchMore, networkStatus } = useQuery(
    AUDIT_LOG,
    {
      variables: { rowId },
      notifyOnNetworkStatusChange: true
    }
  )

  const allLogs = data?.userAuditLogs || []

  if (loading) return <p>Checking for logs...</p>

  if (!allLogs.length) return <p>No changes found</p>

  const allNodes = [
    ...(users || []),
    ...(positions || []),
    ...(assets || []),
    ...(positionCategories || []),
    ...(categories || []),
    ...(propNodes || [])
  ]

  return (
    <>
      <ListGroup>
        {allLogs.map(log => (
          <CollapsablePanel
            buttonOptions={{
              style: {
                justifyContent: 'flex-start'
              },
              openIcon: 'down arrow',
              closeIcon: 'up arrow',
              text: (
                <>
                  <ListGroupItemHeading
                    sx={{
                      mb: '0px',
                      fontSize: '14px'
                    }}
                  >
                    <Badge>{log.action}</Badge> by {log.actionBy} at{' '}
                    {prettyDate(log.timestamp)}
                  </ListGroupItemHeading>
                </>
              )
            }}
            hideDropdown={log.details.length < 1}
          >
            {() => {
              return (
                <ul>
                  {log.details.map((detail, k) => {
                    return (
                      <LogDetailRenderer
                        allNodes={allNodes}
                        key={k}
                        detail={detail}
                        contextData={contextData}
                        includeFieldName={includeFieldNameWithContext}
                      />
                    )
                  })}
                </ul>
              )
            }}
          </CollapsablePanel>
        ))}
      </ListGroup>
      {allLogs.length % 20 === 0 && (
        <Button
          variant="primary"
          style={{ margin: 'auto' }}
          disabled={networkStatus === 3 || loading}
          icon={networkStatus === 3 || loading ? 'spinner' : ''}
          onClick={() =>
            fetchMore({
              variables: {
                cursor: {
                  id: last(allLogs).id
                },
                skip: 1
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev

                return Object.assign({}, prev, {
                  userAuditLogs: uniqBy(
                    [
                      ...prev.userAuditLogs,
                      ...fetchMoreResult.userAuditLogs
                    ],
                    function(e) {
                      return e.id
                    }
                  )
                })
              }
            })
          }
        >
          Load more
        </Button>
      )}
    </>
  )
}

function mapLogValueToNode(value, allNodes) {
  if (value?.includes(',')) {
    return value
      .split(',')
      .map(v => allNodes?.find(a => a.id === v)?.name || v)
      .join(', ')
  }

  const nodeValue = allNodes?.find(a => a.id === value)
  return nodeValue?.name || nodeValue?.label || value
}

function getLogContextData(contextData, contextIds) {
  return contextData
    ?.filter(ctx => contextIds?.includes(ctx.id))
    ?.map(ctx => {
      return (
        ctx.name || ctx.title || ctx.helpText || `${ctx.prettyType}`
      )
    })
    ?.join(', ')
}

const LogDetailRenderer = ({
  detail,
  allNodes,
  contextData,
  includeFieldName,
  ...props
}) => {
  const context = getLogContextData(contextData, detail.contextIds)
  const oldV = mapLogValueToNode(detail.oldValue, allNodes)
  const newV = mapLogValueToNode(detail.newValue, allNodes)

  return (
    <li {...props}>
      <span sx={{ textTransform: 'capitalize' }}>
        {includeFieldName
          ? `${context} ${detail.prettyFieldName}`
          : context || detail.prettyFieldName}
      </span>
      : <ValueText text={oldV} />{' '}
      {newV ? (oldV ? ` changed to ` : `added `) : 'Deleted'}{' '}
      <ValueText text={newV} />
    </li>
  )
}

const ValueText = ({ text }) => {
  const dataIsBase64 = text?.includes('data:image')
  return (
    <strong sx={{ textTransform: 'capitalize' }}>
      {dataIsBase64 ? (
        <img
          sx={{
            bg: 'white',
            mx: '5px',
            height: '40px'
          }}
          src={text}
          alt="preview"
        />
      ) : (
        stripHtml(text)
      )}
    </strong>
  )
}

const UserActionAuditModal = ({
  rowId,
  contextData,
  includeFieldNameWithContext,
  propNodes,
  ...props
}) => {
  return (
    <ModalButton
      buttonProps={{
        title: 'View full history',
        icon: 'restore',
        variant: 'link'
      }}
      modalHeader="Changelog"
      {...props}
    >
      {() => (
        <UserActionAuditModalContent
          contextData={contextData}
          rowId={rowId}
          includeFieldNameWithContext={includeFieldNameWithContext}
          propNodes={propNodes}
        />
      )}
    </ModalButton>
  )
}

export default UserActionAuditModal
