import { useContext } from 'react'
import { Flex, Box } from 'theme-ui'

import DatePickerInput from '../../../Forms/DatePickerInput'

import { cloneDeep } from '@apollo/client/utilities'

import CustomSelectInput from 'src/app/components/Forms/CustomSelectInput'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

import Icon from 'src/app/components/Shared/Icon'
import {
  FormContext,
  getIsValAnswerSingleOrArray,
  isValidValue,
  normalizeScalarOrArray
} from './utils'
import { getFieldConfig } from '../formBuilder/dataMapping'
import Badge from 'src/app/components/Shared/Elements/Badge'
import Input from 'src/app/components/Shared/Elements/Input'

import { StatefulGoogleInput } from 'src/app/components/Forms/GoogleMapInput'
import { TimeDurationInput } from 'src/app/components/Shared/Elements/TimeDurationInput'
import filterNodesByType from 'src/utils/filterNodesByType'
import { RichTextWithEpandedView } from 'src/app/components/Shared/RichTextInput'
import PhoneInput from 'src/app/components/Shared/Forms/PhoneInput'
import { UppyFileUploadButton } from 'src/app/components/Shared/Uppy/AuthenticatedUppyFilePicker'

import FormError from 'src/app/components/Forms/FormError'

import SignatureInputModal from './SignatureInputModal'
import { getContrastColor } from 'src/utils/getContrastColor'
import InputGroupText from 'src/app/components/Shared/Elements/InputGroupText'

const FieldItemRenderer = ({
  id,
  type,
  isUpdate,
  fieldId,
  archived,
  required,
  helpText,
  allowOther,
  allowMultipleAnswers,
  valueTypeFormat,
  useAssignment,
  allowedValues
}) => {
  const agencyInfo = useGetCurrentAgencyContext()
  const {
    values,
    setValues,
    handleErrors,
    errors,
    loading,
    isSupervisorReview,
    autoSave
  } = useContext(FormContext)

  const normalizeAllowedValues = normalizeScalarOrArray(
    allowedValues,
    type === 'number'
  )

  const errorMessage = errors[id]

  // find the field being entered in the list of fields
  const currentFieldIndex = values.fields.findIndex(
    f => f.id === fieldId
  )
  // find the field item being edited in the list of field items
  const currentFieldItemIndex = values.fields[
    currentFieldIndex
  ].fieldItems.findIndex(fi => fi.id === id)

  const currentFieldItem =
    values.fields[currentFieldIndex].fieldItems[currentFieldItemIndex]
  // get the field items current value
  const currentFieldItemValue =
    currentFieldItem[type] || currentFieldItem.value

  // check if the value is an other value meaning it does not match the allowed values
  const currentFieldItemOtherValue =
    currentFieldItemValue &&
    (Array.isArray(currentFieldItemValue)
      ? currentFieldItemValue.find(v => v?.other)?.other
      : currentFieldItemValue)

  // handle change will update the state for this specific field item in its specific field
  function handleChange(value, key, skipAutoSave) {
    handleErrors(id, true)
    let valClone = cloneDeep(values)

    if (key) {
      valClone.fields[currentFieldIndex].fieldItems[
        currentFieldItemIndex
      ][key] = value
    } else {
      valClone.fields[currentFieldIndex].fieldItems[
        currentFieldItemIndex
      ].value = value

      valClone.fields[currentFieldIndex].fieldItems[
        currentFieldItemIndex
      ][type] = value
    }
    if (isUpdate) {
      const currentUpdatedFieldItemIndex = valClone.updateFieldItems.findIndex(
        f => f.id === id
      )

      const newData = {
        id,
        fieldId,
        [key || type]: value
      }

      if (currentUpdatedFieldItemIndex < 0) {
        valClone.updateFieldItems = [
          ...valClone.updateFieldItems,
          newData
        ]
      } else {
        valClone.updateFieldItems[currentUpdatedFieldItemIndex] = {
          ...valClone.updateFieldItems[currentUpdatedFieldItemIndex],
          ...newData
        }
      }
    }

    setValues(valClone)

    if (!skipAutoSave) {
      autoSave(valClone)
    }
  }

  const typeConfig = getFieldConfig({ type })

  const relatedNodeOptions =
    typeConfig.treatAsRelation &&
    filterNodesByAllowedValues(
      agencyInfo[type],
      normalizeAllowedValues
    )

  const finalNodeOptions =
    typeConfig.treatAsRelation &&
    filterNodesByType(relatedNodeOptions, valueTypeFormat)

  const isScalarTypeNumberOrString = checkScalarIsNumberOrString(type)
  const fullWidth =
    normalizeAllowedValues?.length > 3 &&
    normalizeAllowedValues?.length <= 20 &&
    isScalarTypeNumberOrString

  return (
    <Flex
      sx={{
        alignItems: ['stretch', null, null, 'center'],
        justifyContent: 'flex-start',
        flexDirection: 'column',
        m: ['5px', null, null, '15px'],
        width: fullWidth
          ? '100%'
          : ['100%', null, null, '45%', '29%', '22%'],
        position: 'relative',
        border: errorMessage ? '1px solid' : 'none',
        borderColor: errorMessage ? 'danger' : 'transparent',
        p: '5px'
      }}
    >
      {helpText || required ? (
        <Flex
          sx={{
            textAlign: 'left',
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            overflowWrap: 'anywhere',
            mb: '10px'
          }}
        >
          {helpText}{' '}
          {(required || allowMultipleAnswers) && (
            <Flex>
              {required && (
                <Box
                  sx={{ px: '3px', color: errorMessage && 'danger' }}
                >
                  {!helpText && 'Required '}*
                </Box>
              )}

              <Box
                sx={{
                  px: '3px',
                  display: allowMultipleAnswers ? 'block' : 'none'
                }}
              >
                (Multi)
              </Box>
            </Flex>
          )}
        </Flex>
      ) : (
        <div />
      )}

      {archived && (
        <Badge
          style={{
            p: '3px',
            marginBottom: '5px',
            textAlign: 'center'
          }}
        >
          Inactive question.
        </Badge>
      )}
      {type === 'boolean' && (
        <Flex sx={{ width: '100%' }}>
          <Flex
            sx={{
              cursor: 'pointer',
              bg: currentFieldItemValue ? 'success' : 'background',
              color: currentFieldItemValue ? 'background' : 'success',
              py: 20,
              justifyContent: 'center',
              flex: 1,
              textAlign: 'center',
              mr: '3px',
              border: currentFieldItemValue
                ? 'none'
                : '1px solid lightgray'
            }}
            as="button"
            type="button"
            onClick={() =>
              handleChange(
                currentFieldItemValue === true ? undefined : true
              )
            }
          >
            <Icon
              color={currentFieldItemValue && 'background'}
              width={'30%'}
              height="45"
              icon="checkmark"
            />
          </Flex>
          <Flex
            as="button"
            type="button"
            sx={{
              cursor: 'pointer',
              bg:
                currentFieldItemValue === false
                  ? 'danger'
                  : 'background',
              color:
                currentFieldItemValue === false
                  ? 'background'
                  : 'danger',
              textAlign: 'center',
              justifyContent: 'center',
              border:
                currentFieldItemValue === false
                  ? 'none'
                  : '1px solid lightgray',
              flex: 1,
              py: 20
            }}
            onClick={() =>
              handleChange(
                currentFieldItemValue === false ? undefined : false
              )
            }
          >
            <Icon
              height="45"
              color={currentFieldItemValue === false && 'background'}
              width={'30%'}
              icon="x"
            />
          </Flex>
        </Flex>
      )}

      {type === 'text' && (
        <SelectOrRadio
          id="text"
          name="text"
          type="textarea"
          placeholder="Type text here..."
          isMulti={allowMultipleAnswers}
          value={currentFieldItemValue}
          onChange={handleChange}
          options={normalizeAllowedValues}
          allowOther={allowOther}
          optionColors={isSupervisorReview?.stateOptions}
          otherValue={currentFieldItemOtherValue}
          format={valueTypeFormat}
        />
      )}
      {type === 'number' && (
        <SelectOrRadio
          id="number"
          name="number"
          isMulti={allowMultipleAnswers}
          value={currentFieldItemValue}
          type="number"
          step="0.01"
          format={valueTypeFormat}
          onWheel={e => e.target.blur()}
          allowOther={allowOther}
          otherValue={currentFieldItemOtherValue}
          placeholder="0"
          options={
            normalizeAllowedValues &&
            normalizeAllowedValues.map(a => parseFloat(a))
          }
          onChange={handleChange}
        />
      )}
      {type === 'dateTime' && (
        <DatePickerInput
          id="dateTime"
          name="dateTime"
          value={currentFieldItemValue || null}
          placeholderText="Select a date.."
          onChange={date => {
            handleChange(date)
          }}
        />
      )}

      {type === 'file' && (
        <Box sx={{ width: '100%' }}>
          <UppyFileUploadButton
            isUpdate={isUpdate}
            disabled={loading}
            files={(currentFieldItemValue || []).filter(
              f => !f.removed
            )}
            onAttach={f =>
              handleChange([...(currentFieldItemValue || []), ...f])
            }
            onDetach={({ id }) => {
              handleChange(
                currentFieldItemValue.map(f =>
                  f.id === id ? { ...f, removed: true } : f
                )
              )
            }}
            show={true}
            multipleFiles={true}
          />
        </Box>
      )}
      {typeConfig.treatAsRelation &&
        (type === 'locations' ? (
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              gap: '15px'
            }}
          >
            {currentFieldItemValue?.length > 0 ? (
              currentFieldItemValue.map(l => (
                <StatefulGoogleInput
                  id="address"
                  name="address"
                  mapAsModal
                  showPrettyAddress
                  value={l || null}
                  inputProps={{
                    disabled: loading
                  }}
                  handleSelect={address => {
                    handleChange([{ ...l, ...address }])
                  }}
                />
              ))
            ) : (
              <StatefulGoogleInput
                id="address"
                name="address"
                mapAsModal
                value={null}
                inputProps={{
                  disabled: loading
                }}
                showPrettyAddress
                handleSelect={address => {
                  handleChange([address])
                }}
              />
            )}
          </Flex>
        ) : (
          <Box sx={{ width: '100%' }}>
            <CustomSelectInput
              id={type}
              name={type}
              isMulti={
                allowMultipleAnswers ||
                (currentFieldItemValue &&
                  currentFieldItemValue.length > 1)
              }
              getOptionLabel={v => v.name}
              getOptionValue={v => {
                return v.id
              }}
              placeholder={`Select ${valueTypeFormat ||
                typeConfig.title}`}
              isClearable={true}
              value={currentFieldItemValue}
              onChange={selected => {
                handleChange(selected)
              }}
              options={finalNodeOptions}
              quickSelectTitle={type === 'users' && 'Select Me'}
              quickSelectFunction={
                type === 'users' &&
                (opts => {
                  return (
                    opts?.find &&
                    opts?.find(o => o.id === agencyInfo?.user?.id)
                  )
                })
              }
            />
            {(type === 'users' || type === 'positions') &&
              useAssignment &&
              currentFieldItemValue && (
                <InputGroupText>
                  Using your current assignment
                </InputGroupText>
              )}
          </Box>
        ))}

      {errorMessage && <FormError customError={errorMessage} />}
    </Flex>
  )
}

const SelectOrRadio = ({
  options = [],
  allowOther,
  otherValue,
  isMulti,
  optionColors,
  value,
  format,
  ...rest
}) => {
  function handleInputChange(val, skipAutoSave) {
    rest.onChange(
      isValidValue(val)
        ? rest.type === 'number'
          ? Array.isArray(val)
            ? val
            : parseFloat(val)
          : val
        : undefined,
      null,
      skipAutoSave
    )
  }

  if (format === 'duration') {
    return (
      <TimeDurationInput
        scale={'s'}
        value={value}
        sx={{ maxWidth: '100%', width: '100%' }}
        {...rest}
        onChange={val => {
          handleInputChange(val > 0 ? val : null)
        }}
      />
    )
  }

  if (format === 'phone') {
    return (
      <PhoneInput
        value={value}
        sx={{ maxWidth: '100%', width: '100%' }}
        onChange={val => {
          handleInputChange(val, true)
        }}
        onBlur={() => {
          handleInputChange(value, false)
        }}
      />
    )
  }

  if (format === 'signature') {
    return (
      <SignatureInputModal
        signature={value}
        onEnd={value => {
          return handleInputChange(value, false)
        }}
      />
    )
  }

  if (format === 'email') {
    return (
      <Input
        value={value}
        sx={{ maxWidth: '100%', width: '100%' }}
        placeholder="Enter an email address"
        onChange={e => handleInputChange(e.target.value, true)}
        onBlur={e => handleInputChange(e.target.value, false)}
      />
    )
  }

  if (!options || options.length === 0) {
    return rest.type === 'number' ? (
      <Input
        value={value}
        sx={{ maxWidth: '100%', width: '100%' }}
        {...rest}
        inputMode="decimal"
        onChange={e => handleInputChange(e.target.value, true)}
        onBlur={e => handleInputChange(e.target.value, false)}
      />
    ) : (
      <RichTextWithEpandedView
        setSavedValue={val => handleInputChange(val, true)}
        onBlur={val => handleInputChange(val, false)}
        savedValue={value}
        sx={{ maxWidth: '100%', width: '100%' }}
      />
    )
  }

  // handle normalizing the current answer for multi choice, text or number
  const valueIsArray = Array.isArray(value)
  const useMultiSelect = isMulti || valueIsArray

  let currentValue = rest && normalizeScalarOrArray(value)

  function handleNewValueArrayOrSingle(currVal, newValue) {
    return currVal && currVal.length > 0
      ? [...currVal, newValue]
      : [newValue]
  }

  function handleSelect(o, isNumber, skipAutoSave) {
    const isOther = o?.hasOwnProperty('other')

    // if we have an other value, we need to handle it differently. also parseFloats if number field
    const newValue = isOther
      ? normalizeScalarOrArray(o.other, isNumber)
      : normalizeScalarOrArray(o, isNumber)

    // if we are multi select, we need to handle the array of values
    if (useMultiSelect) {
      const newValueWithOther = isOther
        ? { other: newValue }
        : newValue
      // check if answer exists in the current array
      const answerExists =
        currentValue &&
        (isOther
          ? currentValue.find(n => n?.other)
          : currentValue.includes(newValueWithOther))
      // if answer exists, remove it, if it is an other value then
      // add as an object { other: newValue } else push the value into the array
      let array = answerExists
        ? isOther
          ? !newValueWithOther.other
            ? currentValue.filter(n => !n.other)
            : currentValue.map(n => (n.other ? newValueWithOther : n))
          : currentValue.filter(n => n !== newValueWithOther)
        : handleNewValueArrayOrSingle(currentValue, newValueWithOther)
      // update the state with an array of values

      handleInputChange(
        array?.filter(a => isValidValue(a)),
        skipAutoSave
      )
    } else {
      // if we are single select, we need to handle the single value..
      // if the value is matches the current value then we need to clear it

      handleInputChange(
        !isOther && currentValue === newValue ? undefined : newValue,
        skipAutoSave
      )
    }
  }

  const otherValueExists =
    isValidValue(otherValue) && !options?.includes(otherValue)

  if (options.length <= 20) {
    return (
      <Flex
        sx={{
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }}
      >
        {options.map((o, k) => {
          const optVal = normalizeScalarOrArray(o)

          const isAnswer = getIsValAnswerSingleOrArray(
            optVal,
            currentValue
          )
          const selectColor = optionColors?.find(
            c => c.state === optVal
          )?.color

          return (
            <Flex
              sx={{
                minWidth: 'auto',
                cursor: 'pointer',
                bg: isAnswer
                  ? selectColor || 'warning'
                  : 'background',
                color: isAnswer
                  ? selectColor
                    ? getContrastColor(selectColor)
                    : 'background'
                  : 'text',
                p: 20,
                justifyContent: 'center',
                mb: '5px',
                textAlign: 'center',
                mr: '5px',
                border: '1px solid',

                borderColor: isAnswer ? 'warning' : 'text'
              }}
              as="button"
              type="button"
              onClick={() => handleSelect(o, rest.type === 'number')}
              key={k}
            >
              {o}
            </Flex>
          )
        })}

        {(allowOther || otherValueExists) && (
          <Input
            type={'textarea'}
            style={{
              maxWidth: '150px',
              border: otherValue && '1px solid green'
            }}
            {...rest}
            name="other"
            value={otherValue || ''}
            onChange={e => {
              handleSelect(
                { other: e.target.value },
                rest.type === 'number',
                true
              )
            }}
            onBlur={() => {
              if (otherValueExists) {
                handleSelect(
                  { other: otherValue },
                  rest.type === 'number',
                  false
                )
              }
            }}
            placeholder="Other..."
          />
        )}
      </Flex>
    )
  }

  if (useMultiSelect) {
    return (
      <Box sx={{ width: '100%' }}>
        <CustomSelectInput
          {...rest}
          placeholder={``}
          isClearable
          isMulti
          getOptionLabel={v => v}
          getOptionValue={v => v}
          onChange={selected => {
            rest.onChange(
              otherValue
                ? [...(selected || []), { other: otherValue }]
                : selected
            )
          }}
          value={currentValue && currentValue.filter(c => !c.other)}
          options={options}
        />
        {(allowOther || otherValueExists) && (
          <Input
            type={'textarea'}
            style={{
              maxWidth: '150px',
              border: otherValue && '1px solid green',
              marginTop: '10px'
            }}
            {...rest}
            name="other"
            value={otherValue || ''}
            onChange={e => {
              handleSelect(
                { other: e.target.value },
                rest.type === 'number',
                true
              )
            }}
            onBlur={e => {
              handleSelect(
                otherValue ? { other: e.target.value } : undefined,
                rest.type === 'number',
                false
              )
            }}
            placeholder="Other..."
          />
        )}
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <CustomSelectInput
        {...rest}
        placeholder={``}
        style={{ width: '100%' }}
        isClearable
        isMulti={false}
        onChange={selected => {
          handleInputChange(selected?.value)
        }}
        value={{
          label: value,
          value
        }}
        options={options.map(o => ({
          label: o,
          value: o
        }))}
      />

      {allowOther || otherValueExists ? (
        <Input
          type={'textarea'}
          style={{
            maxWidth: '200px',
            marginTop: '10px',
            border: otherValue && '1px solid green'
          }}
          {...rest}
          value={otherValue || ''}
          onChange={e => {
            handleSelect(
              { other: e.target.value },
              rest.type === 'number',
              true
            )
          }}
          onBlur={e => {
            handleSelect(
              otherValueExists ? { other: otherValue } : undefined,
              rest.type === 'number',
              false
            )
          }}
          placeholder="Type Other"
        />
      ) : (
        ''
      )}
    </Box>
  )
}

function checkScalarIsNumberOrString(type) {
  return type === 'text' || type === 'number'
}

function filterNodesByAllowedValues(nodes, allowedValues) {
  if (!allowedValues || allowedValues?.length < 1) return nodes
  return nodes?.filter(
    p =>
      allowedValues.includes(p.id) ||
      p.tags?.some(t => allowedValues.includes(t.id)) ||
      allowedValues.includes(p.category?.id)
  )
}

export default FieldItemRenderer
