import { Fragment } from 'react'

import { Box, Flex } from 'theme-ui'
import { Space } from '..'
import Button from './Elements/Button'

const BreadCrumb = ({
  items,
  activeItem,
  clearForm,
  clearLabels,
  actionButton
}) => {
  if (items.length > 0) {
    return [
      <Flex
        key={1}
        sx={{
          width: '100%',
          mb: '10px',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '3px'
        }}
      >
        {clearLabels && (
          <Button variant="secondary" onClick={() => clearLabels()}>
            Change Category
          </Button>
        )}
        {clearForm && (
          <Button onClick={() => clearForm()} variant="danger">
            Reset Incident
          </Button>
        )}
        {actionButton}
      </Flex>,
      <Flex
        sx={{
          gap: '6px',
          alignItems: 'center',
          padding: '.75rem 1rem',
          bg: 'gray300',
          my: '1rem'
        }}
        key={2}
      >
        {items.map((item, key) => {
          if (!item) return <Fragment />
          return (
            <Box key={key} active={activeItem === key}>
              {item} {key !== items.length - 1 && ' / '}
            </Box>
          )
        })}
        <Space fillRow width={20} widthOnMobile={0} />
      </Flex>
    ]
  } else {
    return (
      <Flex
        key={1}
        sx={{
          width: '100%',
          mb: '10px',
          justifyContent: 'flex-end'
        }}
      >
        {clearForm && (
          <Button
            onClick={() => clearForm()}
            variant="danger"
            sx={{ mr: 1 }}
          >
            Reset Incident
          </Button>
        )}
      </Flex>
    )
  }
}

export default BreadCrumb
